<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.contact')"
          :pgIcon="'bx bx-flag'"
          :refs="refs"
          :pgCurrent="pgCurrent"
          :addNew="false">
      </Breadcrumb>

      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-8">


          <CardInput
              :label="$t('view.name')"
              :input="row.name"
              v-on:inputChange="handleNameChange">
          </CardInput>

          <CardInput
              :label="$t('view.email')"
              :input="row.email"
              v-on:inputChange="handleEmailChange">
          </CardInput>

          <CardInput
              :label="$t('view.subject')"
              :input="row.subject"
              v-on:inputChange="handleSubjectChange">
          </CardInput>

          <div class="form-group col-12">
            <label
                for="input2"
                class="col-form-label"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''">
              {{ $t('view.messages_info') }}
            </label>
            <textarea
                id="input2"
                rows="9"
                class="form-control"
                :class="($i18n.locale == 'ar') ? 'hacen_algeria font-size-15' : ''"
                v-model="row.message">
                        </textarea>
          </div>
        </div>
      </div>


      <Buttons
          v-if="!pgLoading"
          :btnLoading="btnLoading"
          :btnCurrent="btnCurrent"

          v-on:submitClicked="handelSubmit"
          v-on:cancelClicked="handleCancel">
      </Buttons>

    </div>
  </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
  name: 'EditOrNew',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
    CardInput: () => import('@/components/CardInput.vue'),
    Buttons: () => import('@/components/Buttons.vue'),
  },
  data() {
    return {
      // auth
      auth: {
        role: '',
        access_token: '',
      },

      // row
      row: {
        name: "",
        email: "",
        subject: "",
        message: "",
      },

      msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
      btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
      methodType: (this.$route.params.id) ? 'PUT' : 'POST',
      pgLoading: (this.$route.params.id) ? true : false,
      pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
      btnLoading: false,

      refs: 'contact',
    }
  },
  mounted() {
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {

    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;
            this.row.name = res.data.row.name;
            this.row.email = res.data.row.email;
            this.row.subject = res.data.row.subject;
            this.row.message = res.data.row.message;
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                name: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },


    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };

      const config = {headers: {'Content-Type': 'multipart/form-data'}};
      const options = {
        url: (this.$route.params.id) ? window.baseURL + '/' + this.refs + '/' + this.$route.params.id : window.baseURL + '/' + this.refs,
        method: this.methodType,
        data: {
          name: this.row.name,
          email: this.row.email,
          subject: this.row.subject,
          message: this.row.message,
        }
      }
      this.axios(options, config)
          .then(() => {
            this.btnLoading = false;
            iziToast.success({
              icon: 'bx bx-wink-smile',
              name: '',
              message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
            });

            this.$router.push({name: this.refs});
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                name: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }
          })
          .finally(() => {
          })

    },


    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('encrypt_id');
      localStorage.removeItem('role');
    },

    // Cancel
    handleCancel() {
      if (confirm('هل انت متأكد؟')) {
        this.$router.push({name: this.refs});
      }
    },

    handleNameChange(event) {
      this.row.name = event.input;
    },

    handleEmailChange(event) {
      this.row.email = event.input;
    },

    handleSubjectChange(event) {
      this.row.subject = event.input;
    },

  },
}
</script>
